<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Текущий_уникальный_номер") }}</label>
                            </v-col>
                            <v-col  cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ current ? current : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{ $t("Новый_уникальный_номер") }}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field
                                    v-model.trim="newNumber"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    :maxlength="18"
                                >
                                    <template v-slot:prepend>
                                        <v-icon
                                            @click="genUniqueNumber"
                                            v-tooltip.left-center="$t('Сгенерировать_уникальный_номер_по_порядку')"
                                        >
                                            mdi-cogs
                                        </v-icon>
                                    </template>
                                    
                                </v-text-field>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="valid"
                >
                    {{ $t("Изменить") }}
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { httpAPI } from "@/api/httpAPI";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ChangeEuolStatementUniqueNumberDlg",
    data () {
        return {
            title: "Изменить",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('dialogs/changeEuolStatementUniqueNumber', { visible: 'isVisible', current: 'getCurrentNumber' }),
        newNumber: {
            get: function() {
                return this.$store.getters['dialogs/changeEuolStatementUniqueNumber/getNewNumber'];
            },
            set: function(v) {
                if (v != this.$store.getters['dialogs/changeEuolStatementUniqueNumber/getNewNumber'])
                    this.$store.commit('dialogs/changeEuolStatementUniqueNumber/SET_NEW_NUMBER', v);
            }
        },
        valid() {
            if (this.newNumber.length == 0 || this.newNumber.length == 18)
                return this.newNumber != this.current;

            return false;
        }
    },
    methods: {
        ...mapActions('dialogs/changeEuolStatementUniqueNumber', ['ok', 'cancel']),
        async genUniqueNumber() {
            let response = await httpAPI({
                url: '/api/references/generateeuoluniquenumber',
                method: 'GET'
            });

            if (response) {
                this.$store.commit('dialogs/changeEuolStatementUniqueNumber/SET_NEW_NUMBER', response.data?.payload?.Message);
            }
        }
    }
}
</script>